import { h, render } from 'preact';
import { getDeep } from 'utilities/obj.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import Control from '../../../shared/controls/Control.js';
import { RoundedShareButton } from './RoundedShareButton.tsx';
import { defineControl } from '../../../../../../shared/control_definitions.js';
import { defineTranslations, getTranslation } from '../../../../../../shared/translations.js';
import { isMouseDownRecently } from '../../../../../../../utilities/isMouseDown.js';

defineTranslations('en-US', {
  SHARE_CONTROL_CLOSE: 'Close sharing menu',
  SHARE_CONTROL_OPEN: 'Open sharing menu',
});

class ShareControl extends Control {
  constructor(video) {
    super(video);
    this.options =
      getDeep(this.video.plugin, 'share.options') ||
      getDeep(this.video._opts, 'plugin.share') ||
      {};
    this.updateFromOptions(this.options);
  }

  mountButton(buttonElem) {
    this.buttonElem = buttonElem;
    this._isDialogOpen = false;
    this.renderButton();
  }

  renderButton() {
    if (!this.buttonElem) {
      return;
    }

    const translationKey = this._isDialogOpen ? 'CLOSE' : 'OPEN';
    const translation = getTranslation(
      this.props.playerLanguage.code,
      `SHARE_CONTROL_${translationKey}`,
    );
    this.setButtonLabel(translation);
    render(<RoundedShareButton onMouseEnter={this.onMouseEnterButton} />, this.buttonElem);
    this.reactMounts.button = [this.buttonElem];
  }

  mountDialog(dialogRoot) {
    this.dialogRoot = dialogRoot;
    return this.renderMenu();
  }

  loadMenuCode = () => {
    return Promise.all([
      dynamicImport('assets/external/interFontFace.js'),
      dynamicImport('assets/external/vulcanV2Player/video/controls/ShareControl/dialog.js'),
    ]).then(([, moduleControlDialog]) => {
      if (!this.renderMenuImpl) {
        const { renderMenu } = moduleControlDialog;
        this.renderMenuImpl = renderMenu.bind(this);
      }
    });
  };

  renderMenu() {
    if (!this.dialog.isOpen()) {
      return;
    }

    const loadMenuCode = this.loadMenuCode().then(() => {
      this.renderMenuImpl();
    });

    this.loading(
      new Promise((resolve) => {
        loadMenuCode.then(resolve);
      }),
    );

    return loadMenuCode;
  }

  onListItemClick = () => {
    if (isMouseDownRecently()) {
      setTimeout(() => {
        this.dialog.close();
        this.buttonElem.parentElement.focus();
      }, 300);
    }
  };

  onMouseEnterButton = () => {
    this.loadMenuCode();
  };

  onClickButton = () => {
    this._isDialogOpen = !this._isDialogOpen;
    this.renderButton();
  };

  updateFromOptions(options) {
    this._channels = options.channels || '';
    this._downloadType = options.downloadType;
    this._pageUrl = options.pageUrl;
    this._pageTitle = options.pageTitle;
    this._tweetText = options.tweetText || '';

    if (options.overrideUrl === null || options.overrideUrl === undefined) {
      this._overrideUrl = true;
    } else {
      this._overrideUrl = options.overrideUrl;
    }

    this.shareOptions = {
      overrideUrl: this._overrideUrl,
      pageTitle: this._pageTitle,
      pageUrl: this._pageUrl,
      tweetText: this._tweetText,
    };
  }

  updateFromOptionsAndRender(options) {
    this.updateFromOptions(options);
    return this.loadMenuCode().then(() => {
      this.dialog.open().then(() => {
        this.renderMenuImpl();
      });
    });
  }

  onControlPropsUpdated(prevProps) {
    if (this.dialog && this.dialog.isOpen()) {
      this.renderMenu();
    }

    if (
      prevProps.playerLanguage &&
      this.props.playerLanguage.code !== prevProps.playerLanguage.code
    ) {
      this.renderButton();
    }
  }
}

ShareControl.shouldMount = (video) => {
  return video.plugin['share-v2'];
};

ShareControl.handle = 'shareButton';
ShareControl.type = 'control-bar-right';
ShareControl.sortValue = 300;
ShareControl.isVideoChrome = true;
defineControl(ShareControl);

export default ShareControl;
